import React from 'react'

// ================= LIBRARIES ================= //
	import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
	import { withTranslation } from 'react-i18next'
	import Spinner from 'react-spinner-material'
	import { isAuthenticated } from './services/authentication'
	import { ToastContainer } from 'react-toastify'
// ================= LIBRARIES ================= //

// ================= CONTEXT ================= //
	import LocaleContext from './contexts/Locale'
// ================= CONTEXT ================= //

// ================= COMPONENTS ================= //
	import LoadingFallback from './components/loading_fallback'
// ================= COMPONENTS ================= //

// ================= STYLES ================= //
	import './assets/styles/global.css'
	import './assets/styles/helpers.css'
	import './assets/scss/icons.min.css'
	import 'react-toastify/dist/ReactToastify.css'

	import "@pathofdev/react-tag-input/build/index.css"

// ================= STYLES ================= //

// ================= THEMES ================= //
	const LightTheme = React.lazy(() => import('./themes/lightTheme'));
	const DarkTheme = React.lazy(() => import('./themes/darkTheme'));
// ================= THEMES ================= //

// ================= PAGES ================= //
	const Login = React.lazy(() => import('./pages/public/Login'))
	const Ativacao = React.lazy(() => import('./pages/public/Ativacao'))
	const ForgotPassword = React.lazy(() => import('./pages/public/ForgotPassword'))
	const PasswordRecovery = React.lazy(() => import('./pages/public/PasswordRecovery'))
	const Layout = React.lazy(() => import('./pages/private/Layout'))
// ================= PAGES ================= //


const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
	  	{...rest}
	  	render={props =>isAuthenticated() ? (
			<Component {...props} />
		) : (
		  	<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
		)
	  }
	/>
)

class App extends React.Component{
    static contextType = LocaleContext


	render(){
		const { t } = this.props

		return(
			<>
				<div className={`application-loader ${this.context.wait ? '' : 'hidden'}`}>
					<div><Spinner radius={50} color={'#fff'} stroke={4} visible={true} /></div>
					<div className="mt-10 text-white">{t("App - Loading")}...</div>
				</div>

				<BrowserRouter>
					<ToastContainer />

					<React.Suspense fallback={<></>}>
						{(this.context.typeTheme  === 'lightTheme') && <LightTheme />}
						{(this.context.typeTheme === 'darkTheme') && <DarkTheme />}
					</React.Suspense>

					<React.Suspense fallback={<LoadingFallback />}>
						<Switch>
							<Route exact path="/login" render={props => <Login {...props}/>} />
							<Route exact path="/forgot-password" render={props => <ForgotPassword {...props}/>} />
							<Route exact path="/password-recovery" render={props => <PasswordRecovery {...props}/>} />
							<Route exact path="/ativacao" render={props => <Ativacao {...props}/>} />
							
							<PrivateRoute path="/" component={props => <Layout {...props}/>} />

						</Switch>
					</React.Suspense>
				</BrowserRouter>
			</>
		)
	}
}

export default withTranslation()(App)