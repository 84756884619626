

export const isAuthenticated  = () => localStorage.getItem('Consta-Token') !== null

export const getToken = () => localStorage.getItem('Consta-Token')

export const login = token => {
    localStorage.setItem('Consta-Token', token)
}

export const logout = () => {
    localStorage.removeItem('Consta-Token')
}

